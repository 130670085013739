
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import GroupService from "@/core/services/GroupService";
import Swal from "sweetalert2";
import UserListSelect from "@/components/common/UserListSelect.vue";
import UserStatus from "@/views/user/UserStatus.vue";

export default defineComponent({
  name: "Members",
  components: {
    UserStatus,
    UserListSelect,
    BaseForm, BaseModal, QuickAction, DateTimeFormat, KtDatatable, Entities
  },
  props: {
    groupId: {}
  },
  setup(props) {
    const submitting = ref(false);
    const model = ref({userIds: []})
    const entity = computed(()=>store.state.GroupModule.entity);
    const filterObject = ref({groupId: props.groupId})
    const userPage = computed(() => store.state.UserModule.userPage)
    const tableHeader = ref([

      {
        name: "Full Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Company",
        key: "company",
        sortable: true,
      },
      {
        name: "Last Login",
        key: "lastLogin",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },

      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      submitting,
      entity,
      model,
      tableHeader,
      ...LoadFilterObjects(Actions.LOAD_USERS, filterObject.value, ['company']),
      userPage,
    }
  },
  methods: {
    onAdd() {
      const modal = this.$refs.addMemberRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSave() {
      this.submitting = true
      GroupService.addUser(this.groupId, this.model).then(() => {
        this.paginationLoad()
        const modal = this.$refs.addMemberRef as typeof BaseModal;
        modal.hideBaseModal();
      }).finally(() => {
        this.submitting = false;
      })
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await GroupService.remove(this.groupId, id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
